import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import IndexBlock from "../components/IndexBlock";
import SEO from "../components/seo";

export default ({ data }) => (
	<Layout>
		<SEO title="Deals" keywords={[`dumbo`, `places`, `dumbo businesses`]} />
		<IndexBlock posts={data.recraft.entries} />
	</Layout>
);

export const query = graphql`
	query {
		recraft {
			entries(section: [deals], limit: null) {
				... on Recraft_Deals {
					title
					uri
					slug
					postDate
				}
			}
		}
	}
`;

// const posts = get(this, "props.data.craft.entries");

// orderBy: "title asc"
